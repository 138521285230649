import { Maybe } from '@wix/wix-data-client-wix-code-adt'
import { appContext } from '../viewer-app-module/DataBindingAppContext'

export const slugResolver = () => () => {
  return Maybe.fromNullable(appContext.platform.location.pageUrl).map(
    pageUrl => {
      const pathSegments = new URL(pageUrl).pathname.split('/')
      const lastSegment = pathSegments[pathSegments.length - 1]
      return lastSegment
    },
  )
}
